import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Facebook,
  Instagram,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({data}) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Horace Bray"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Horace Bray"
        videoUrl="https://vimeo.com/500672604/978bce56f6"
      />
      <Width pt="12.9rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"nicholas"}
                fluid={data.horaceBrayImage.childImageSharp.fluid}
              />
            </Box>
          </Box>
          <Box width={["100%", null, 8 / 12, null]}>
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Horace Bray
            </Text>
            <Text level="1" mb="1.2rem">
              Guitarist and performing artist
            </Text>
            <Box>
              <Link to="https://www.instagram.com/horacebray" as="a">
                <Instagram mr="1.2rem" />
              </Link>
              <Link to="https://www.facebook.com/Horacebraymusic/" as="a">
                <Facebook />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Horace Bray is a guitarist, singer, and producer based in Los
              Angeles, California. Horace studied jazz performance and music
              theory at the University of North Texas (UNT). As part of the
              internationally recognized jazz studies program, he was the first
              undergraduate in 15 years to hold the guitar chair in the Grammy
              nominated One O’clock Lab Band. After graduating from UNT in 2015,
              Horace debuted his self produced and critically acclaimed jazz
              album, Dreamstate, before moving to New York City. While living in
              New York City, Horace released two singer/songwriter EPs, How It
              Ends and Rosa.
            </Text>
            <Text level="2">
              In addition to performing his own music, Horace has played for{" "}
              <strong>
                India Arie, Jordan Fisher, Taylor Dayne, Sofia Carson, Sam
                Fischer, John Splithoff, Michael Blume, Abir,
              </strong>{" "}
              and more. Horace is an accomplished educator, teaching college
              clinics on improvisation, jazz, and songwriting around the world.
              After moving to Los Angeles in 2019, Horace launched a lofi hip
              hop project, <strong>Felty</strong>, under the Dutch label{" "}
              <strong>Chillhop</strong>. The debut EP, Movement, received 600k
              plays in the first month and features producers{" "}
              <strong>Blue Wednesday, Ezzy,</strong> and{" "}
              <strong>G Mills</strong>. Horace has amassed millions of views on
              different platforms and has attracted over 134K international
              followers.
            </Text>
          </Box>
          
        </Box>
        </Width>
      <MentorFooter data={data} name="Horace Bray" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    horaceBrayImage: file(
      relativePath: { eq: "horaceBray-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
